import { useStorage } from '@/common/storage'
import { authAPI } from '@/api/auth'
import { defineStore } from 'pinia'
import Taro, { UserInfo } from '@tarojs/taro'
import { store } from '..'
import { useMessage } from '@/common/message'

/* types 类型注释 */
interface UserState {
  token: string; // 登录标识符
  user: Nullable<Model.BdUser & CustomModel.BdUser>, // 用户信息
  openid: string; // 微信用户标识符
  weappOpenid: string;
  wxCode: string; // 微信登录码
  wxCodeIsUsed: boolean; // 微信登录码使用状态
  userMobile: number | string; // 登陆人手机号
  wxUserInfo: UserInfo | null // 微信用户信息
  isPersonal: boolean // 当前是否是个人身份
  isDistributor: boolean // 用户经销商状态
}

/* data 数据 */
export const useUserStore = defineStore('User', {
  state: (): UserState => ({
    token: useStorage.getItem('token') || '', // 默认 token
    openid: '',
    weappOpenid: useStorage.getItem('weapp_openid') || '',
    user: useStorage.getItem('user') || '',
    wxCode: '',
    wxCodeIsUsed: false,
    userMobile: '',
    wxUserInfo: null,
    isPersonal: true, // 个人 || 企业
    isDistributor: false
  }),
  actions: {

    /* 设置用户信息之后 项目定制内容 */
    afterSetUserInfo(user: Model.BdUser & CustomModel.BdUser) {
      // 预留逻辑
    },

    /* h5登录  项目定制内容,微信浏览器授权登录 */
    async loginH5(code: string) {
      const { tokenType, accessToken } = await authAPI.loginCode({ code }) // 登录
      this.token = accessToken
      useStorage.setItem('token', accessToken)
      await this.setUserInfo()
      // if (!this.user?.mobile) {
      //   return
      // }
      // Taro.navigateBack()
      // Taro.navigateTo({ url: '/pages/tab-bar/my' })

    },

    /* h5登录  项目定制内容,非微信浏览器,手机号登录 */
    async loginH5ByMobile(tokenType: string, accessToken: string) {
      this.token = accessToken
      useStorage.setItem('token', accessToken)
      await this.setUserInfo()
    },

    async loginMinipro(e) {

      /*       if (!isEmpty(this.weappOpenid)) {
        const { accessToken } = await authAPI.loginMiniProgramByOpenId({
          openid: this.weappOpenid
        })
        this.token = accessToken
        useStorage.setItem('token', accessToken)
        await this.setUserInfo()
        await useMessage.success('登录成功')
      } else  */
      if (e.detail.encryptedData) {
        const { iv, encryptedData } = e.detail // 获取手机号接口调用所需参数
        const { openid, sessionKey } = await authAPI.getMiniProgramOpenId(this.wxCode)
        this.wxCodeIsUsed = true
        this.weappOpenid = openid
        const { accessToken } = await authAPI.loginByMiniprogram({
          openid,
          iv,
          encryptedData,
          sessionKey
        })
        this.token = accessToken
        useStorage.setItem('token', accessToken)
        useStorage.setItem('weapp_openid', openid)
        await this.setUserInfo()
        await useMessage.success('登录成功')
      }
    },

    /* 获取微信用户信息 */
    async setWxUserInfo() {
      await Taro.getUserProfile({
        desc: '用于登录',
        success: res => {
          this.wxUserInfo = res.userInfo
        }
      })
    },

    /* 重新加载微信登陆码 */
    async reloadWxCode() {
      this.wxCodeIsUsed = false
      this.wxCode = (await Taro.login()).code
    },

    // /* 设置用户信息 */
    async setUserInfo() {
      const user: Model.BdUser & CustomModel.BdUser = await authAPI.getUserInfo()
      this.user = user
      this.isPersonal = user.accountType === 0 // 0 个人 1 企业
      this.isDistributor = user.isDistributor
      useStorage.setItem('user', user)
      this.afterSetUserInfo(user)
    },

    /* 设置微信用户标识符 */
    setOpenid(openid) {
      this.openid = openid
    },

    // 退出登录
    async logout(requestApi?: boolean) {
      requestApi && (await authAPI.logout())
      this.token = ''
      this.user = null
      useStorage.removeItem('token')
      useStorage.removeItem('user')
    },

    /* 检查登录 */
    checkLogin() {
      if (!this.user || !this.token) {
        // throttleToLogin()
      }
      return Boolean(this.token && this.user?.mobile)
    },
  },
})

export function useUserWithoutStore() {
  return useUserStore(store)
}
