import { useUserWithoutStore } from './store/modules/user'
import { useGlobalWithoutStore } from './store/modules/global'
import { setupStore } from './store'
import { setupComponent } from './components/setup-component'
import { createApp } from 'vue'
import './styles/app.css'
import 'windi.css'
import './assets/icon-font/iconfont.css' // 解决H5样式引入问题
import './assets/nut-icon-font/iconfont.css' // 解决H5样式引入问题
import setupUpdateCheck from './common/update-check'
import { fixArray } from './common/fixArray'
import { loadEcharts } from 'echarts4taro3'
import * as echarts from './assets/echarts/echarts'
// import { setupFundebug } from './common/debug'

const globalStore = useGlobalWithoutStore()
const userStore = useUserWithoutStore()

const app = createApp({

  onLaunch() {
    fixArray() // 修复数组at()方法
    globalStore.setReference()
    loadEcharts(echarts)
    setupUpdateCheck()
    // console.log(process.env.NODE_ENV)
    // globalStore.setSystemInfo()
    // globalStore.setMiniProgramInfo() // 设置小程序信息
    if (userStore.token) {
      userStore.setUserInfo()
    }

    /*       console.log(useEnv, 'useEnv') */
  },
})


setupComponent(app)
setupStore(app)
// setupFundebug(app)
export default app
