import Taro from '@tarojs/taro'
import { isWeChatMiniProgram, isWeiXin } from './isWeixin'
import { useStorage } from '@/common/storage'

export function throttle(func, wait) {
  let last = 0
  return (...params) => {
    const current_time = +new Date()
    if (current_time - last > wait) {
      func.apply(this, params)
      last = +new Date()
    }
  }
}

export const throttleToLogin = throttle((uri = '/pages/auth/login', redirect = '') => {
  // 接口返回401，token失效后，应该把本地的token和user清理掉
  useStorage.removeItem('token')
  useStorage.removeItem('user')
  // 判断是否是微信浏览器
  if (isWeiXin()) {
    if (redirect) {
      Taro.navigateTo({ url: `${uri}?redirect=${encodeURIComponent(redirect)}` })
    } else {
      Taro.navigateTo({ url: uri })
    }
  } else if (redirect) {
    if (isWeChatMiniProgram()) {
      Taro.navigateTo({ url: `/pages/auth/login-by-miniprogram?redirect=${encodeURIComponent(redirect)}` })
    } else {
      Taro.navigateTo({ url: `/pages/auth/login-by-mobile?redirect=${encodeURIComponent(redirect)}` })
    }
  } else if (isWeChatMiniProgram()) {
    Taro.navigateTo({ url: '/pages/auth/login-by-miniprogram' })
  } else {
    Taro.navigateTo({ url: '/pages/auth/login-by-mobile' })
  }
}, 2000)