<!-- 数字输入框 -->
<template>
  <div class="flex items-center justify-end">
    <img
      :src="subImg"
      class="w-20px h-20px"
      @click="sub"
    >
    <input
      v-bind="getBindValue"
      ref="customNumberInputRef"
      class="w-100px h-24px rounded-4px flex items-center mx-10px"
      style="border: 1px solid #ccc;"
      max="12"
    >
    <img
      :src="addImg"
      class="w-20px h-20px"
      @click="add"
    >
  </div>
</template>

<script lang="ts" setup>
import { computed, useAttrs, ref } from 'vue'
import { customNumberInputProps } from './props'
import addImg from '@/assets/images/add.png'
import subImg from '@/assets/images/sub.png'

/* data 数据 */
const customNumberInputRef = ref()
const props = defineProps(customNumberInputProps)
const attrs = useAttrs()
const emits = defineEmits(['update:modelValue', 'add', 'sub'])
const alignObj = {
  left: 'text-left',
  right: 'text-right',
  center: 'text-center',
}

// input的min和max没有效果
const getBindValue = computed(() => ({
  class: alignObj[props.textAlign] || '',
  value: props.modelValue,
  type: 'number',
  oninput: e => {
    const value = e.detail.value.replace(/[^0-9]/gi, '').slice(0, props.maxLength)
    if (value === props.modelValue) {
      emits('update:modelValue', e.detail.value)
    }
    emits('update:modelValue', value)
  },
  ...attrs
}))
const add = () => {
  if (props.modelValue) {
    const v = parseInt(props.modelValue) + 1
    emits('add', `${v}`)
    emits('update:modelValue', `${v}`)
  } else {
    // 一开始就点击加号的时候，默认给它赋值1
    const v = 1
    emits('update:modelValue', `${v}`)
  }
}
const sub = () => {
  if (props.modelValue) {
    const v = parseInt(props.modelValue) - 1
    if (props.gZero && v <= 0) {
      return
    }
    emits('sub', `${v}`)
    emits('update:modelValue', `${v}`)
  }
}
</script>