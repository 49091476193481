import { request } from '@/common/request'
import { UserInfo } from '@tarojs/taro'

const authAPI = {
  getUserInfo: () => request.get('/api/user/users/info'), // 获取用户详情
  getOpenid: (code: string, wxUserInfo: UserInfo) => request.post('/miniapp/get_openid', {
    code,
    wxUserInfo
  }),
  // 获取 openId
  getMiniProgramOpenId: (code: string) => request.post('/api/login/getOpenIdByMiniProgram', {
    code,
  }),
  // 微信小程序登录
  loginByMiniprogram: params => request.post('/api/login/loginByMiniProgram', params),
  // 微信小程序openid登录
  loginMiniProgramByOpenId: params => request.post('/api/login/loginMiniProgramByOpenId', params),
  logout: () => request.post('/logout'), // 登出
  getCaptcha: params => request.post('/captcha', params), // 获取验证码
  getCaptchaPic: params => request.get('/captcha/pic', params), // 获取图片验证码
  loginCode: params => request.post('/api/login', params), // 公众号登录
  bindMobile: params => request.post('/api/user/users/mobile', params), // 绑定手机号
  bindIdcard: params => request.post('/api/user/users/idcard', params), // 绑定个人信息
  loginByMobile: params => request.post('/api/login/mobile', params), // 非微信浏览器下手机号登录
  bindMobileIdcard: params => request.post('/api/user/users/bindInfo', params),
}

export { authAPI }
