type Env = {

  /** 公共路径 */
  publicPath: string;

  /** 基础请求路径，已实现代理 */
  baseApiUrl: string;

  baseUrl: string;

  /** 文件上传路径 */
  uploadApiUrl: string;

  /** 静态资源URL */
  imageUrl: string;

  /** 项目名称 */
  siteName: string;

  /** 当前程序运行环境 */
  // appEnv?: 'development' | 'test' | 'production';

  /** 当前是否为开发环境 */
  isDevelopment: boolean;

  /** 当前是否为测试环境 */
  isTest: boolean;

  /** 当前是否为生产环境 */
  isProduction: boolean;

  appid: string;
}

export const useEnv: Env = {
  publicPath: '' as string,
  baseApiUrl: process.env.API_URL ?? '',
  baseUrl: process.env.BASE_URL ?? '',
  uploadApiUrl: '' as string,
  imageUrl: process.env.IMAGE_URL ?? '',
  siteName: '背调',
  isDevelopment: process.env.NODE_ENV === 'development',
  isTest: process.env.NODE_ENV === 'testing',
  isProduction: process.env.NODE_ENV === 'production',
  appid: process.env.WEIXIN_APPID ?? '',
}
