import { useMessage } from '@/common/message'
import { projectConfig } from '@/common/project-config'
import { useUserStore } from '@/store/modules/user'
import { useGlobalStore } from '@/store/modules/global'
import axios, { AxiosInstance, AxiosRequestConfig, Method } from 'taro-axios'
import { isUndefined } from 'lodash-es'
import Taro from '@tarojs/taro'
import { throttleToLogin } from '@/common/throttle'
import { isWeChatMiniProgram } from '@/common/isWeixin'

// 自定义 axios 配置项
type CustomAxiosRequestConfig = AxiosRequestConfig & {
  isMock?: boolean; // 是否使用 yapi 接口模拟功能
  withLoading?: boolean; // 该接口调用时，添加 loading 效果
  returnRes?: boolean; // 返回原始 resp 响应信息，用于获取 http 原信息，如 status code 等
  withoutCheck?: boolean; // 直接返回接口结果，不经过全局处理器
  withoutMsg?: boolean; // 直接返回接口结果，不经过全局处理器
}

const UNAUTH_CODE = 401
const SUCCESS_CODE = 200

/* data 数据 */
const loadingApi: { [key: string]: boolean } = {} // 加载中的 Api 接口，对非幂等接口，进行统一接口节流

/* methods 方法 */
class Request {
  private axiosInstance: AxiosInstance

  constructor(opt: AxiosRequestConfig) {
    this.axiosInstance = axios.create(opt)
    this.setupIntercetors()
  }

  setBaseUrl(newPath) {
    this.axiosInstance.defaults.baseURL = newPath
  }

  get<T = any>(url: string, params?: Recordable, otherConfig?: CustomAxiosRequestConfig): Promise<T> {
    return this.request(url, 'GET', params, otherConfig)
  }

  post<T = any>(url: string, params?: Recordable, otherConfig?: CustomAxiosRequestConfig): Promise<T> {
    return this.request(url, 'POST', params, otherConfig)
  }

  put<T = any>(url: string, params?: Recordable, otherConfig?: CustomAxiosRequestConfig): Promise<T> {
    return this.request<T>(url, 'PUT', params, otherConfig)
  }

  delete<T = any>(url: string, params?: Recordable, otherConfig?: CustomAxiosRequestConfig): Promise<T> {
    return this.request<T>(url, 'DELETE', params, otherConfig)
  }

  upload<T = any>(url: string, params?: Recordable, otherConfig?: CustomAxiosRequestConfig): Promise<T> {
    const headers = {
      'Content-Type': 'multipart/form-data',
    }
    const formData = new FormData()
    if (params) {
      Object.keys(params).forEach(key => {
        formData.append(key, params[key])
      })
    }
    return this.request(url, 'POST', formData, {
      headers,
      ...otherConfig
    })
  }

  request<T = any>(url: string, method: Method, params?: Recordable, otherConfig?: CustomAxiosRequestConfig): Promise<T> {
    const globalStore = useGlobalStore()

    const config: CustomAxiosRequestConfig = {
      url: otherConfig?.isMock || projectConfig.isMock
        ? `${projectConfig.mockApiURL}${url}`
        : url, // 是否使用模拟接口,
      method,
      params,
      ...otherConfig,
    }

    /* 默认开启加载lodaing */
    if (isUndefined(otherConfig?.withLoading)) {
      config.withLoading = true
    }

    return new Promise((resolve, reject) => {
      if (loadingApi[url]) {
        useMessage.loading('网络请求中，请稍后')

        /*         reject('网络请求中，请稍后') */
        return
      }

      /* 过滤无用参数 */
      for (const key in config.params) {
        if (config.params[key] !== config.params[key] || config.params[key] === undefined || config.params[key] === null) {
          delete config.params[key]
        }
      }

      if (
        // 强制设置 loading
        config.withLoading === true
        // 未设置 false 强制关闭，则默认 post 类型请求添加loading
        || (config.withLoading !== false
          && (method.toUpperCase() === 'POST' || method.toUpperCase() === 'PUT'))
      ) {
        useMessage.loading('网络请求中...')
      }

      loadingApi[url] = true
      if (method.toUpperCase() !== 'GET' && method.toUpperCase() !== 'DELETE') {
        config.data = config.params
        config.params = {}
      }
      const defaultMsg = '请联系管理员'
      this.axiosInstance
        .request(config)
        .then(res => {
          delete loadingApi[url]
          if (useMessage.getToastNum() <= 0) {
            useMessage.hideLoading()
          }

          // 不需要校验及解析，直接返回整个 res 对象
          if (config.returnRes) {
            return resolve(res as any)
          }

          // 不需要校验，直接返回整个 data 数据对象
          if (config.withoutCheck) {
            return resolve(res.data)
          }

          const { code, data, msg, pagination } = res.data
          const dataNew = pagination ? {
            items: data,
            pagination
          } : data

          if (code !== SUCCESS_CODE) {
            // 获取当前路由的参数
            switch (code) {
              case UNAUTH_CODE:
                break
              default:
                if (!config.withoutMsg) {
                  useMessage.error(msg || defaultMsg)
                }
                break
            }

            /*             debugNotify('接口请求失败', msg, {
              requestData: config,
              responseData: res.data,
              systemInfo: globalStore.systemInfo
            }) */

            reject(msg)
          } else {
            resolve(dataNew as T)
          }
        })
        .catch((error: any) => {

          /*           debugNotifyError({
            message: `http请求失败 ${error.message}`,
            requestData: config,
          }, error) */
          delete loadingApi[url]
          if (useMessage.getToastNum() <= 0) {
            useMessage.hideLoading()
          }

          /* 401状态时跳转登录页 */
          if (error?.response?.status === UNAUTH_CODE) {
            let currentPages = Taro.getCurrentPages()
            let lastPage = currentPages[currentPages.length - 1]
            let lastTaroPath = lastPage.$taroPath
            if (lastTaroPath[0] !== '/') {
              lastTaroPath = `/${lastTaroPath}`
            }
            let taroParams = lastPage.$taroParams
            // 最后一个页面不是登录页的话，统一拦截处理
            if (!lastTaroPath.includes('/pages/auth/login')) {
              if (lastTaroPath.includes('?')) {
                lastTaroPath = lastTaroPath.split('?')[0]
              }
              let params
              // 处理路由参数，拼接重定向uri
              if (Object.keys(taroParams).length > 0) {
                delete taroParams.stamp
                delete taroParams.$taroTimestamp
                // eslint-disable-next-line max-depth
                if (isWeChatMiniProgram()) {
                  const paramsArray = []
                  // eslint-disable-next-line max-depth
                  for (const key in taroParams) {
                    // eslint-disable-next-line max-depth
                    if (taroParams.hasOwnProperty(key)) {
                      paramsArray.push(`${key}=${encodeURIComponent(taroParams[key])}`)
                    }
                  }
                  params = paramsArray.join('&')
                } else {
                  params = new URLSearchParams(taroParams).toString()
                }
              }
              if (params) {
                lastTaroPath += `?${params}`
              }
              throttleToLogin('/pages/auth/login', lastTaroPath)
            }
            reject('登录状态过期')
            return
          }
          useMessage.error(error.message)
          reject(error.message)
        })

    })
  }

  setupIntercetors() {
    this.axiosInstance.interceptors.request.use(config => {
      const userStore = useUserStore()
      const globalStore = useGlobalStore()

      config.headers.WeappId = globalStore.miniProgramInfo?.appId
      if (userStore.token && config.headers) {
        config.headers.Authorization = `Bearer ${userStore.token}`
        config.headers.Platform = 'wechat'
        config.headers.Version = 'v1'
      }

      return config
    })
  }
}

const request = new Request({
  baseURL: projectConfig.apiURL,
  timeout: projectConfig.requestTimeout,
  // 自定义网络超时提示
  timeoutErrorMessage: '网络超时',
  responseType: 'json',
  headers: {
    Accept: 'application/json',
  },
})

export {
  request
}
