import { useEnv } from '@/common/env'
import noFoundEmptyImg from '@/assets/images/no-fount-empty.png'
import shareGuideImg from '@/assets/images/share-guide.png'
import wenhaoImg from '@/assets/images/wenhao.png'
import blueCoinImg from '@/assets/images/blue-coin.png'
import blackRightArrow from '@/assets/images/black-right-arrow.png'
import personImg from '@/assets/images/person.png'
import personAddImg from '@/assets/images/person-add.png'
import personRightArrowImg from '@/assets/images/person-right-arrow.png'
import blackCoinImg from '@/assets/images/black-coin.png'
import lineBarImg from '@/assets/images/line-bar.png'
import buildingsImg from '@/assets/images/buildings.png'
import grayRightArrowImg from '@/assets/images/gray-right-arrow.png'
import orangeRightArrowImg from '@/assets/images/orange-right-arrow.png'
import orangeEmptyImg from '@/assets/images/orange_empty.png'
import blueRightArrowImg from '@/assets/images/blue-right-arrow.png'
import inviteTotalImg from '@/assets/images/invite-total.png'
import whiteRightSanjiaoImg from '@/assets/images/white-right-sanjiao.png'

export const useImage = {
  whiteRightSanjiaoImg,
  noFoundEmptyImg,
  shareGuideImg,
  wenhaoImg,
  blueCoinImg,
  blackRightArrow,
  personImg,
  personAddImg,
  personRightArrowImg,
  blackCoinImg,
  lineBarImg,
  buildingsImg,
  grayRightArrowImg,
  orangeRightArrowImg,
  orangeEmptyImg,
  blueRightArrowImg,
  inviteTotalImg,
}

export function getOnlineImageFullUrl(name) {
  return `${useEnv.imageUrl}/assets/images/h5/${name}`
}